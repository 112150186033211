import React, { useState } from "react";
import {getAuth} from 'firebase/auth';
import { logInWithEmailAndPassword, signInWithGoogle } from "../../firebase";
import { TextInput, Button } from "@tremor/react";

function LoginForm() {

  const auth = getAuth();
  const [formData, setFormData] = useState({email:"", password:""});

  return (
    <form onSubmit={
      (event) => {
        event.preventDefault();
      }
    }>
      <fieldset className="field_entry">
        <label htmlFor="email">Email Address</label>
        <TextInput 
        id="email" 
        type="email" 
        placeholder="Enter email..." 
        onChange={(event)=> setFormData({...formData,email:event.target.value})} 
        />
      </fieldset>
      <fieldset className="field_entry">
        <label htmlFor="password">Password</label>
        <TextInput 
        id="password" 
        type="password" 
        placeholder="Enter password..." 
        onChange={(event)=> setFormData({...formData,password:event.target.value})} 
         />
      </fieldset>
      <div className="flex gap-5 flex-wrap">
        <div className="login-button-entry">
          <Button
            className="login__btn"
            onClick={() => logInWithEmailAndPassword(auth, formData.email, formData.password)}
          >
            Login
          </Button>
        </div>
        <div className="login-button-entry">
          <Button className="login__btn login__google" onClick={signInWithGoogle}>
            Sign In with Google
          </Button>
        </div>
      </div>
      <div style={{ marginTop: '50px' }}>
        <p className="text-[14px]">New to Wallabie? <a className="underline underline-offset-2" href="/signup/">Create your account</a></p>
      </div>
    </form>
  )
}

export default LoginForm;