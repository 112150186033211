import React, { useState, useEffect, useCallback } from "react";

// Routing
import { useParams } from "react-router-dom";

//Firebase
import { auth, db } from "../../firebase";
import { query, collection, getDocs, where, getFirestore, doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

// Components
import MessageForm from "../MessageForm";
import MessageList from "../MessageList";
import WallControls from "../WallControls";

// UI
import UserBar from "../UserBar";

// Styles
import './styles.scss';

function MessageBoard() {

  /* User State
  ========================================================= */
  const [user] = useAuthState(auth);
  const [isLoading, setLoading] = useState(true);

  /* User Data
  ========================================================= */
  const [theme, setTheme] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [wallowner, setUserUid] = useState("");

  /* Page Data
  ========================================================= */
  const pageParams = useParams();
  const pageSlug = pageParams.pageSlug;
  const [pageId, setPageId] = useState("");

  const getPageId = useCallback(async () => {
    const q = query(collection(db, "pages"), where("pageSlug", "==", pageSlug));
    const docSnap = await getDocs(q);
    if (!docSnap.empty) {
      setPageId(docSnap.docs[0].id);
    }
    else {
      setPageId(pageSlug);
    }
  },[pageSlug]);

  useEffect(() => {
    getPageId();
  }, [getPageId])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = getFirestore();
        const docRef = doc(db, "pages", pageId);
        getDoc(docRef).then((docSnap) => {
          const docObject = docSnap.data();
          const themeSelection = docObject['themeSelection'];
          const title = docObject['title'];
          setTheme(themeSelection);
          setPageTitle(title);
        });
      }
      catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [pageId]);


  // get the user.uid based on the pageId from Firestore
  const fetchWallowner = useCallback(async () => {
    try {
      const db = getFirestore();
      const docRef = doc(db, "pages", pageId);
      getDoc(docRef).then((docSnap) => {
        const docObject = docSnap.data();
        const owner = docObject['owner'];
        setUserUid(owner);
      });
    }
    catch (error) {
      console.log(error);
    }
  }, [pageId]);

  useEffect(() => {
    fetchWallowner();
  }, [fetchWallowner])

  /* Message State
  ========================================================= */

  const [messageEntries, setMessageEntries] = useState([]);

  /* Message Functions
  ========================================================= */

  /* Handle Message Submit */

  const handleFormSubmit = async ({ message, messageName }) => {
    if (!wallowner) {
      console.error('wallowner not set');
      return;
    }
    const newMessageRef = doc(collection(db, 'messages'));
    await setDoc(newMessageRef, {
      message,
      messageName,
      pageId,
      owner: wallowner,
      messageStatus: 0,
      createdAt: new Date(),
    });
    setMessageEntries(prevMessages => [...prevMessages, {
      message,
      messageName,
      messageStatus: 0,
      messageId: newMessageRef.id,
    }]);

    const updatePageDoc = async (pageId) => {
      try {
        const pageRef = doc(db, "pages", pageId);
        await updateDoc(pageRef, {
          messageCount: messageEntries.length + 1,
        });
      }
      catch (error) {
        console.log(error);
      }
    }
    updatePageDoc(pageId);
  }

  /* Fetch all messages for this PageId */

  const fetchMessages = useCallback(async () => {
    try {
      const messagesCollection = query(collection(db, "messages"), where("pageId", "==", pageId));
      const messageQuery = await getDocs(messagesCollection);
      const nextMessages = [];
      messageQuery.forEach((message) => {
        const messageObject = message.data();
        const messageContent = messageObject.message;
        const messageStatus = messageObject.messageStatus;
        const messageName = messageObject.messageName;
        const messageId = message.id;
        nextMessages.push(
          {
            message: messageContent,
            messageName: messageName,
            messageStatus: messageStatus === undefined ? 0 : messageStatus,
            messageId: messageId
          }
        );
        setMessageEntries(nextMessages);
      });
    }
    catch (error) {
      console.log(error);
    }
  }, [pageId]);

  useEffect(() => {
    fetchMessages();
  }, [pageId, fetchMessages]);


  useEffect(() => {
    const messagesCollection = query(collection(db, "messages"), where("pageId", "==", pageId));
    getDocs(messagesCollection).then(data => {
      setLoading(false);
    }).catch(error => {
      console.error("Error fetching messages:", error);
    })
  }, [pageId]);

  
  /* User Redirect
  ========================================================= */

  return (
    <>
      {isLoading === false &&
        <div className={`wall-theme ${theme} min-h-screen`}>

          <UserBar
            user={user}
          />

          <div className="container mx-auto text-center pb-10">

            <WallControls
              user={user}
              pageId={pageId}
              setTheme={setTheme}
            />

            <MessageList
              pageTitle={pageTitle}
              messageEntries={messageEntries}
              user={user}
            />

            <MessageForm
              onSubmit={handleFormSubmit}
            />

          </div>

        </div>
      }
    </>
  )
}

export default MessageBoard;