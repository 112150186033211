import React, { useState } from "react";
import { collection, getFirestore, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { Card, Text, Button } from "@tremor/react";
import { XCircleIcon } from "@heroicons/react/24/solid";


function MessageEntry({ messageContent, messageName, messageStatus, messageId, user }) {

  const db = getFirestore();
  const messagesCollection = collection(db, "messages");
  const messageDocRef = doc(messagesCollection, messageId);
  const [messageRemoved, setMessageRemoved] = useState(false);

  /* Message Status
  ========================================================= */
  const [status, setStatus] = useState(messageStatus);
  const toggleStatus = async (e) => {
    e.preventDefault();
    const newStatus = status === 1 ? 0 : 1;
    await updateDoc(messageDocRef, {
      messageStatus: newStatus
    });
    setStatus(newStatus);
  }

  const deleteMessage = async (e) => {
    e.preventDefault();
    if (window.confirm('Are you sure you wish to delete this item?')) {
      await deleteDoc(messageDocRef);
      setMessageRemoved(true);
    }
  }

  /* Message Content
  ========================================================= */
  const MessageInstance = ({ isAdmin }) => {
    return (

      <Card className={`text-center mb-10 max-w-[300px] xl:max-w-[500px] mx-auto ${status === 0 ? "opacity-75" : "opacity-100"}`} decoration="bottom" decorationColor={`${status === 0 ? "rose" : "sky"}`}>
        <Text>
          {messageContent}
        </Text>
        {!!messageName &&
          <Text>
            - {messageName}
          </Text>
        }
        {isAdmin &&
          <div className="flex mt-5 justify-center gap-5">

            {messageRemoved ?
              <p className="font-bold text-cyan-700 text-sm">Message Removed!</p>
              :
              <>
                <Button variant={status === 1 ? "secondary" : "primary"} onClick={toggleStatus}>
                  {status === 1 ? "Unapprove" : "Approve"}
                </Button>        
                <Button icon={XCircleIcon} iconPosition="right" color="red" variant="light" onClick={deleteMessage}>
                  Remove
                </Button>
                </>
            }
          </div>
        }
      </Card>
    )
  }



  /* Init
  ========================================================= */
  return (
    <>

      {user && user.email !== null ?
        <MessageInstance isAdmin />
        : status === 1 &&
        <MessageInstance />
      }
    </>
  )
}

export default React.memo(MessageEntry);