//Firebase
import { db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";

// Icons 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCakeCandles, faChampagneGlasses, faRainbow } from '@fortawesome/free-solid-svg-icons'

function ThemeSelector({ onSetTheme, pageId }) {

  const changeTheme = async (theme) => {
    try {
      const page = doc(db, 'pages', pageId);
      await updateDoc(page, {
        themeSelection: theme
      });
      onSetTheme(theme);
    }
    catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="theme-selection">
      <div className="flex justify-center align-center gap-10">
        <button onClick={() => changeTheme('theme-birthday')} className="theme-selection-entry">
          <div className="icon text-2xl">
            <FontAwesomeIcon icon={faCakeCandles} />
          </div>
          <div className="label text-xs">Birthday</div>
        </button>
        <button onClick={() => changeTheme('theme-celebration')} className="theme-selection-entry">
          <div className="icon text-2xl">
            <FontAwesomeIcon icon={faChampagneGlasses} />
          </div>
          <div className="label text-xs">Celebration</div>
        </button>
        <button onClick={() => changeTheme('theme-memorial')} className="theme-selection-entry">
          <div className="icon text-2xl">
            <FontAwesomeIcon icon={faRainbow} />
          </div>
          <div className="label text-xs">Memorial</div>
        </button>
      </div>
    </div>
  );
}

export default ThemeSelector;