
import MessageEntry from "../Message";

function MessageList({ messageEntries, user, pageTitle}) {

  return (
    <>

      <h2 className="pt-10">{pageTitle}</h2>

      {messageEntries.length < 1 ?
        <h3>Nobody has written anything yet...</h3>
        :
        <div className="message-entries">
          {
            messageEntries.map((message, index) => (
              <MessageEntry
                key={index}
                messageId={message.messageId}
                messageStatus={message.messageStatus}
                messageContent={message.message}
                messageName={message.messageName}
                user={user}
              />
            )
            )
          }
        </div>}
    </>
  )
}

export default MessageList;