import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@tremor/react";
import { HomeIcon, UserIcon } from "@heroicons/react/24/solid";
import logo from '../../assets/images/logo.svg';

function Navbar() {

  return (
    <>
      <div className="user-bar-wrap pt-7 pb-5 mb-5 bg-white/90 shadow-md shadow-neutral-100/50">
        <div className="container mx-auto">
          <div className="flex items-center justify-end gap-5">
            <Link to="/dashboard" className="mr-auto ml-0">
            <img className="max-w-[200px]" src={logo} alt="" />
            </Link>
              <Link href="/" className="user-bar-entry">
                <Icon
                  icon={HomeIcon}
                  variant="light"
                  tooltip="Home"
                  color="slate"
                />
              </Link>
              <Link to="/login" className="user-bar-entry">
                <Icon
                  icon={UserIcon}
                  variant="light"
                  tooltip="Login"
                  color="slate"
                  className="transition"
                />
              </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar;