import './assets/scss/App.scss';

import { React } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './components/Home';
import Signup from './components/Signup';
import Login from './components/Login';
import Settings from './components/Settings';
import Dashboard from './components/Dashboard';
import InviteUsers from './components/InviteUsers';
import MessageBoard from './components/MessageBoard';
import Contacts from './components/Contacts';
import { FileUploadProvider } from './components/FileUploadContext';

function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup/" element={<Signup />} />
          <Route exact path="/settings/" element={<Settings />} />
          <Route exact path="/dashboard/" element={
            <FileUploadProvider>
              <Dashboard />
            </FileUploadProvider>
          } />
          <Route exact path="/contacts/" element={<Contacts/>} />
          <Route exact path="/invite/" element={
            <FileUploadProvider>
              <InviteUsers />
            </FileUploadProvider>
          } />
          <Route path="/pages/:pageSlug" element={<MessageBoard/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;