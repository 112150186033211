import React, { useState } from "react";
import { TextInput, Button } from "@tremor/react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function CreateWall() {

    /* User State
  ========================================================= */
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

    /* Page Collection
  ========================================================= */
  const pagesRef = collection(db, "pages");

  /* Page Data
  ========================================================= */
  const [title, setTitle] = useState("");
  const [textError, setTextError] = useState(false);

  /* Form Functions
  ========================================================= */

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title) {
      setTextError(true);
      return;
    }

    addDoc(pagesRef, {
      title,
      owner: user.uid,
      createdAt: new Date()
    })
      .then((docRef) => {
        const newPageId = docRef.id;
        navigate(`/pages/${newPageId}`);
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  return (
    
      <form onSubmit={handleSubmit}>
        <TextInput errorMessage="" error={textError} className="mb-3" type="text" placeholder="Page Title..."
          onChange={
            (event) => setTitle(event.target.value)
          }
        />
        <Button type="submit">Create Wall</Button>
      </form>
    
  )
}

export default CreateWall;