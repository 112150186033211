import React, { createContext, useState, useContext } from 'react';

// Create Context
const FileUploadContext = createContext();

// Create Context Provider
export function FileUploadProvider({ children }) {
  const [fileCount, setFileCount] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [fileUploads, setFileUploads] = useState([]);
  const [uploadComplete, setUploadComplete] = useState(false);

  const onFileCount = (fileCount) => {
    setFileCount(fileCount);
  }

  return (
    <FileUploadContext.Provider value={{fileCount, setFileCount, onFileCount, fileUrl, setFileUrl, fileUploads, setFileUploads, uploadComplete, setUploadComplete }}>
      {children}
    </FileUploadContext.Provider>
  );
}

// Custom hook to use the FileUploadContext, this can be used inside any functional component.
export function useFileUpload() {
  const context = useContext(FileUploadContext);
  if (context === undefined) {
    throw new Error('useFileUpload must be used within a FileUploadProvider')
  }
  return context;
}
