import React, { useState } from "react";

// React Router
import { useNavigate } from "react-router-dom";

// Components
import Navbar from "../Navbar";
import ConfettiExplosion from 'react-confetti-explosion';
import { CSSTransition } from 'react-transition-group';

// Tremor
import { Card, Badge, Button, Divider, Text, TextInput, Icon, Callout } from "@tremor/react";

// Icons
import { HandThumbUpIcon, CheckCircleIcon } from "@heroicons/react/24/solid";

// Assets
import home01 from '../../assets/images/home-01.jpg';
import home02 from '../../assets/images/home-02.jpg';

// Styles
import './styles.scss';

function Home() {

  const navigate = useNavigate();


  /* Confetti
  ========================================================= */
  const [isExploding, setIsExploding] = useState(false);
  const confettiProps = {
    force: 0.1,
    duration: 2200,
    particleCount: 80,
    width: 800,
    height: "120vh",
    colors: ['#0ea5e9', '#7c3aed', '#f43f5e'],
  }

  /* Wall Form
  ========================================================= */

  const [testWallTitle, setTestWallTitle] = useState("");
  const [submitWall, setSubmitWall] = useState(false);
  const submitTestWall = (e) => {
    e.preventDefault();
    setSubmitWall(true);
    setTimeout(() => {
      setSubmitWall(false)
    }, 2000)
  }
  /* Contact Form
  ========================================================= */

  const [testName, setTestName] = useState("");
  const [testAddress, setTestAddress] = useState("");
  const [submitContact, setSubmitContact] = useState(false);
  const handleTestSubmit = (e) => {
    e.preventDefault();
    setSubmitContact(true);
    setTimeout(() => {
      setSubmitContact(false)
    }, 2000)
  }

  return (
    <>
      <Navbar />
      <div className="container">

        <div className="home-intro pb-10 md:pb-10">
          <div className="py-10 flex flex-col md:flex-row place-content-center flex-wrap gap-10">
            <div className="home-intro--primary prose flex-initial basis-6/12">
              <h1 className="text-cyan-600 font-light">A space where people can celebrate important life events
                meaningfully and authentically <span className="font-medium text-sky-700">together</span> from <span className="font-medium text-sky-700">anywhere</span></h1>
              <p>This is a space where people can share heartfelt messages outside of the clutter and cacophony of social media. It’s not an e-card or message board, it’s a virtual space where people can <span className="font-medium text-sky-600">come and honor</span>. </p>

              <p>This is the gift to your loved one. It is an honoring. And somewhat like planning a surprise party it takes work to put together, with a rewarding and often tear-eyed reception.</p>
            </div>
            <div className="home-intro--secondary relative flex-initial basis-3/12">
              <div className="image-wrap relative">
                <img className="w-full block" src={home01} alt="" />
                <img className="w-full block mt-10 md:mt-0 md:absolute top-[calc(100%-50px)] right-[-50px]" src={home02} alt="" />
              </div>
            </div>
          </div>
        </div>

        <Divider />

        <div className="home-overview py-16">
          <h2 className="text-center text-cyan-600 font-light">We want to generate long-term connection and belonging across the world</h2>

          <div className="max-w-3xl mx-auto prose">
            <p>With Wallabie, users can celebrate important life events virtually and meaningfully. "Walls" are semi-private places which draw together friends and family to honor a mutual loved one.</p>
            <p>Whereas eCards are only from an individual (or at most a family), our experience gathers loved ones in a shared space to honor and celebrate milestones as a group gesture, fostering a feeling of true acknowledgment, community and belonging.</p>
          </div>
        </div>

        <div className="home-steps">

          <h2 className="text-center text-cyan-600 font-normal">All good things come in threes:</h2>

          <div className="step-entry flex flex-col md:flex-row gap-10 items-center justify-center max-w-3xl mx-auto pt-6 pb-24 border-b border-solid border-slate-200">
            <div className="step-title flex-initial w-full md:basis-6/12 md:w-6/12">

              <h2 className="flex items-center mb-3">
                <Badge className="mr-2 bg-sky-600" size="xl"><strong className="text-xl text-white">1</strong></Badge>
                Start Your Wall</h2>
              <p>As something of a mix between a timeline and an eCard, Walls are where your community will share their heartfelt messages.</p>
            </div>
            <div className="step-example flex-initial w-full md:basis-6/12 md:w-6/12 relative">
              <Card decoration="bottom" decorationColor="teal">
                <form onSubmit={submitTestWall}>
                  <TextInput errorMessage="" className="mb-3" type="text" value={testWallTitle} placeholder="Page Title..." onChange={(e) => setTestWallTitle(e.target.value)}
                  />
                  <Button type="submit">Create Wall</Button>
                </form>
                <CSSTransition
                  in={submitWall}
                  timeout={300}
                  classNames="confirmMessage"
                  onExited={() => {
                    setSubmitWall(false);
                    setTestWallTitle("");
                  }}
                  mountOnEnter
                  unmountOnExit
                  appear
                >
                  <Callout
                    className="confirmation-message mt-5 mb-2 absolute top-10 right-0 left-0 mx-auto w-80"
                    title="You did it!"
                    icon={HandThumbUpIcon}
                    color="cyan"
                  >
                    <Text><span className="font-bold">{testWallTitle}</span> created! 🎉</Text>
                  </Callout>
                </CSSTransition>
              </Card>
            </div>
          </div>

          <div className="step-entry flex flex-col md:flex-row gap-10 items-center justify-center max-w-3xl mx-auto py-24 flex-row-reverse border-b border-solid border-slate-200">
            <div className="step-title flex-initial w-full md:basis-6/12 md:w-6/12">

              <h2 className="flex items-center mb-3">
                <Badge className="mr-2 bg-sky-600" size="xl"><strong className="text-xl text-white">2</strong></Badge>
                Add Your Contacts
              </h2>
              <p>Next is to add all the names/email addresses you want to invite to your wall(s).</p>
            </div>
            <div className="step-example flex-initial w-full md:basis-6/12 md:w-6/12">
              <Card decoration="bottom" decorationColor="teal">
                <form onSubmit={handleTestSubmit}>
                  <div className="flex flex-col md:flex-row flex-col gap-5 mb-5">
                    <TextInput
                      placeholder="Name..."
                      value={testName}
                      onChange={(e) => setTestName(e.target.value)}
                    />
                    <TextInput
                      placeholder="Email..."
                      value={testAddress}
                      onChange={(e) => setTestAddress(e.target.value)}
                    />
                  </div>
                  <div className="text-right">
                    <Button>
                      Add Contact
                    </Button>
                  </div>
                </form>

                <CSSTransition
                  in={submitContact}
                  timeout={300}
                  classNames="confirmMessage"
                  onExited={() => {
                    setSubmitContact(false);
                    setTestName("");
                    setTestAddress("");
                  }}
                  mountOnEnter
                  unmountOnExit
                  appear
                >
                  <h3 className="font-bold confirmation-message m-0 flex items-center mt-4">
                    <Icon
                      icon={CheckCircleIcon}
                    /><span className="text-sky-500 mr-1">{testAddress}</span> has been added!</h3>
                </CSSTransition>

              </Card>
            </div>
          </div>

          <div className="step-entry flex flex-col md:flex-row gap-10 items-center justify-center max-w-3xl mx-auto py-24 border-b border-solid border-slate-200">
            <div className="step-title flex-initial w-full md:basis-6/12 md:w-6/12">

              <h2 className="flex items-center mb-3">
                <Badge className="mr-2 bg-sky-600" size="xl"><strong className="text-xl text-white">3</strong></Badge>
                Invite Your People
              </h2>
              <p>Like a fingerprint, every Wallabie invite is different, and contains a unique token that allows them to leave their special message for the lucky recipient.</p>
            </div>
            <div className="step-example flex-initial w-full md:basis-6/12 md:w-6/12">
              <Card decoration="bottom" decorationColor="teal">

                {isExploding &&
                  <ConfettiExplosion
                    {...confettiProps}
                  />}

                <h3 className="font-bold">You're inviting 57 amazing people! 🎉</h3>
                <Button
                  onClick={() => {
                    setIsExploding(true);
                    setTimeout(() => {
                      setIsExploding(false);
                    }, 2000)
                  }}
                  type="submit">Send Invites</Button>
              </Card>
            </div>
          </div>

          <div className="step-entry max-w-3xl mx-auto pt-12 pb-24">
            <h2 className="text-4xl text-center mb-5">
              ...then feel the love.
            </h2>
            <p className="max-w-xl mx-auto mb-16">Watch as the good vibes roll in. You'll have complete control of which messages show, and in what order. When the time is right, you'll share the link with that special someone...and fill their cup, too! 💖</p>
            <div className="step-example">

              <Card className="text-center mb-10 max-w-[300px] xl:max-w-[500px] mx-auto" decoration="bottom" decorationColor="sky">
                <Text
                  className="leading-7"
                >
                  Hey Michael! I got news about your college graduation! 🎓 <br /> I wish I could be there in person, but I wanted to send a note to tell you how proud we all are of you! 🥹
                </Text>
                <Text className="mt-5 font-medium italic">
                  Love, Uncle Dylan
                </Text>
              </Card>

              <Card className="text-center mb-10 max-w-[300px] xl:max-w-[500px] mx-auto" decoration="bottom" decorationColor="teal">
                <Text
                  className="leading-7"
                >
                  Sarah, I'm so happy that you're going to be moving closer to us! <br /> We can't wait to show you around. <br /> You're going to love the food here! 😋 🍕
                </Text>
                <Text className="mt-5 font-medium italic">
                  Jillian &amp; Adam
                </Text>
              </Card>

              <Card className="text-center mb-10 max-w-[300px] xl:max-w-[500px] mx-auto" decoration="bottom" decorationColor="indigo">
                <Text
                  className="leading-7"
                >
                  James! It's about time you and Michelle finally tied the knot! 💒 If I don't make it to the wedding, consider this my formal toast to you both! You two truly deserve each other. 🥂
                </Text>
                <Text className="mt-5 font-medium italic">
                  Sam (from high school...go Lancers!)
                </Text>
              </Card>
            </div>
          </div>

        </div>

        <div className="home-signup pt-12 pb-36 border-t border-solid border-slate-200">

          <h2 className="text-center mb-10">
            Hop into Wallabie and connect your community.
          </h2>
          <div className="flex flex-col md:flex-row gap-5 flex-wrap items-center justify-center">
            <div className="login-button-entry">
              <Button
                onClick={() => navigate('/login/')}
              >
                Sign In
              </Button>
            </div>
            <div className="login-button-entry">
              <Button
                onClick={() => navigate('/signup/')}
              >
                Create Account
              </Button>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default Home;