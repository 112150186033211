import React, { useState, useRef, useEffect, useCallback } from "react";

/* React Router */
import { useNavigate } from "react-router-dom";

/* Firebase */
import { db, auth, storage } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { doc, arrayUnion, arrayRemove, query, collection, getDoc, getDocs, updateDoc, where } from "firebase/firestore";

/* Tremor */
import { Button, Card, Icon, Toggle, ToggleItem, Table, TableHead, TableHeaderCell, TableBody, TableRow, TableCell, TextInput } from "@tremor/react";
import { DocumentArrowUpIcon, CheckCircleIcon } from "@heroicons/react/24/solid";

/* Utils */
import { formatBytes } from "../../utils";
import Papa from "papaparse";

/* Components */
import UserBar from "../UserBar";


function Contacts() {

  /* Auth
========================================================= */
  const [user] = useAuthState(auth);

  /* React Router
  ========================================================= */
  const navigate = useNavigate();


  /* File Handling
  ========================================================= */
  const [chosenFile, setChosenFile] = useState("");
  const [fileSelected, setFileSelected] = useState(false);

  const [fileSize, setFileSize] = useState("");

  const [fileUrl, setFileUrl] = useState("");

  const [progresspercent, setProgresspercent] = useState(0);
  const [headerVisibility, setHeaderVisibility] = useState(false);

  const fileRef = useRef(null);
  const uploadHeaderRef = useRef(null);

  const handleFileSelect = (event) => {
    if (event.target.files.length > 0) {
      setChosenFile(event.target.files[0].name);
      setFileSize(formatBytes(event.target.files[0].size));
      setFileSelected(true);
    } else {
      setFileSelected(false);
    }
  };

  /* Upload File
  ========================================================= */
  const handleFileUpload = (e) => {

    e.preventDefault();

    const file = e.target[1].files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rows = results.data;
        setImportedEmails(rows);
      },
    });

    const storageRef = ref(storage, `${auth.currentUser.uid}/files/${file.name}`);
    const metadata = {
      contentType: file.type,
      customMetadata: {
        file_name: file.name,
        uploadDate: new Date().toISOString(),
        userId: auth.currentUser.uid,
        userEmailAddress: auth.currentUser.email,
      },
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on("state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgresspercent(Math.floor(progress));
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFileUrl(downloadURL);
        }
        );
      }
    );

    setFileSelected(false);
    setFileUrl("");
    setProgresspercent(0);
  };

  /* Upload Notification
  ========================================================= */
  useEffect(() => {
    let timeoutId;
    if (progresspercent === 100) {
      setHeaderVisibility(true);
      timeoutId = setTimeout(() => {
        setHeaderVisibility(false);
      }, 1000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }, [fileUrl, progresspercent]);


  /* Importing
  ========================================================= */

  const [allChecked, setAllChecked] = useState(false);
  const [importedEmails, setImportedEmails] = useState([]);
  const [fetchedEmails, setFetchedEmails] = useState([]);

  /* Select All
  ========================================================= */

  const handleSelectAll = (e) => {
    if (allChecked) {
      setSelectedEmails([]);
    }
    else {
      setSelectedEmails(fetchedEmails)
    }
    setAllChecked(!allChecked);
  }


  /* Fetch Contacts
========================================================= */

  const fetchContacts = useCallback(async () => {
    if (user) {
      const usersDB = collection(db, "users");
      const userQuery = query(usersDB, where("uid", "==", user.uid));
      getDocs(userQuery).then((querySnap) => {
        const userDocId = querySnap.docs[0].id;
        const docRef = doc(db, "users", userDocId);
        getDoc(docRef).then((docSnap) => {
          const docData = docSnap.data();
          setFetchedEmails(docData.contacts);
        })
      }).catch((error) => {
        console.log(error);
      });
      setSelectedEmails([]);
    }
  }, [user]);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  /* Manually Add (with error checking)
  ========================================================= */

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const [errors, setErrors] = useState({ firstName: false, lastName: false, email: false });
  const handleManualSubmit = useCallback((e) => {
    e.preventDefault();
    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;
    const email = emailRef.current.value;
    const updatedErrors = {
      firstName: !firstName,  // error will be true if firstName is empty
      lastName: !lastName,    // error will be true if lastName is empty
      email: !email           // error will be true if email is empty
    };
    setErrors(updatedErrors); // set the error states
    const hasErrors = Object.values(updatedErrors).includes(true);
    if(!hasErrors) {
    setImportedEmails([
      ...importedEmails,
      {
        first_name: firstName,
        last_name: lastName,
        email: email
      }
    ]);
    firstNameRef.current.value = '';
    lastNameRef.current.value = '';
    emailRef.current.value = '';
    firstNameRef.current.focus();
  }
  },[importedEmails]);

  /* Upload Contacts
  ========================================================= */

  const handleDataUpload = useCallback(async () => {
    if (user) {
      const usersDB = collection(db, "users");
      const userQuery = query(usersDB, where("uid", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(userQuery);
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const contacts = importedEmails.map(address => {
          return {
            firstName: address.first_name,
            lastName: address.last_name,
            emailAddress: address.email
          };
        });

        try {
          await updateDoc(doc(db, "users", userDoc.id), { contacts: arrayUnion(...contacts) });
          console.log("Contacts successfully updated!");
          fetchContacts();
        } catch (error) {
          console.error("Error updating document: ", error);
        }

      } else {
        console.log('No document found for the given uid');
      }
    }
  }, [importedEmails, fetchContacts, user]);


  useEffect(() => {
    handleDataUpload();
  }, [importedEmails, handleDataUpload, handleManualSubmit]);


  /* Remove Contact
  ========================================================= */

  const [selectedEmails, setSelectedEmails] = useState([]);


  const handleChange = (email, isChecked) => {
    if (isChecked) {
      if (!selectedEmails.includes(email)) {
        setSelectedEmails([...selectedEmails, email]);
      }
    }
    else {
      setSelectedEmails(selectedEmails.filter(item => item !== email));
    }
  }

  const removeContact = async (contacts) => {
    const contactsArray = Array.isArray(contacts) ? contacts : [contacts];
    console.log(contactsArray);
    const usersDB = collection(db, "users");
    const userQuery = query(usersDB, where("uid", "==", user.uid));
    getDocs(userQuery).then(async (querySnap) => {
      const userDocId = querySnap.docs[0].id;
      const docRef = doc(db, "users", userDocId);
      await updateDoc(docRef, {
        contacts: arrayRemove(...contactsArray)
      });
      setSelectedEmails([]);
      fetchContacts();
    }).catch((error) => {
      console.log(error);
    });
  }

  /* Tabs
  ========================================================= */

  const [settings, setSettings] = useState('');

  return (
    <>
      <div className="dashboard-wrap">
        <UserBar
          user={user}
        />
        <div className="dashboard-wrap-inner container mx-auto pb-10">
          <h2 className="text-center">Upload Contacts</h2>

          <div className="contacts-upload-toggle flex justify-center">
            <Toggle className="mb-5 text-center" defaultValue={settings} onValueChange={(value) => setSettings(value)}>
              <ToggleItem value="csv" text="Upload CSV" />
              <ToggleItem value="manual" text="Manually Enter" />
            </Toggle>
          </div>

          {settings === 'csv' &&

            <form className="mt-5 mb-10" onSubmit={handleFileUpload}>

              <fieldset className="field_entry mb-5">

                <input
                  ref={fileRef}
                  id="file"
                  type="file"
                  className="hidden"
                  onChange={handleFileSelect}
                />

                <div className="text-center">
                  <Button
                    icon={DocumentArrowUpIcon}
                    accept=".csv"
                    onClick={(e) => {
                      e.preventDefault();
                      fileRef.current.click();
                    }}>
                    Select File
                  </Button>
                </div>
              </fieldset>

              {!fileUrl && progresspercent > 0 &&
                <div className="mt-4 mb-2 rounded bg-gray-200 w-full rounded-2xl overflow-hidden">
                  <div className="transition-all will-change px-1 py-2 height-full w-0 bg-teal-500 overflow-hidden" style={{ width: `${progresspercent}%` }}></div>
                </div>
              }

              {headerVisibility &&
                < h3 ref={uploadHeaderRef} className="font-bold text-stone-400 flex items-center justify-center"> <Icon icon={CheckCircleIcon} />Upload complete</h3>
              }

              {fileSelected &&
                <div className="mb-10">
                  <p className="mb-2 text-xs">Selected file: <em>{chosenFile} ({fileSize})</em></p>
                  <div className="flex gap-5">
                    <Button type="submit">Upload</Button>
                    <Button variant="secondary" onClick={
                      () => {
                        setFileSelected(false)
                      }
                    }>
                      Cancel
                    </Button>
                  </div>
                </div>
              }
            </form>
          }

          {settings === 'manual' &&
            <Card className="manually-add my-10 max-w-2xl mx-auto">
              <h2 className="text-xl font-medium text-center">Manual Enter</h2>
              <form onSubmit={handleManualSubmit}>
                <div className="flex gap-5 mb-5 flex-col sm:flex-row">
                  <TextInput 
                  error={errors.firstName} 
                  ref={firstNameRef} 
                  placeholder="First name" 
                  />
                  <TextInput 
                  error={errors.lastName} 
                  ref={lastNameRef} 
                  placeholder="Last name" 
                  />
                  <TextInput 
                  error={errors.email} 
                  ref={emailRef} 
                  placeholder="Email address" 
                  />
                </div>
                <div className="text-right">
                  <Button>
                    Add Contact
                  </Button>
                </div>
              </form>
            </Card>
          }

          <Card>
            <div className="mb-5 flex justify-end items-center">
              {fetchedEmails.length > 0 &&
                <Button
                  className="mr-auto"
                  onClick={handleSelectAll}
                >
                  {allChecked ? "Deselect" : "Select"} All
                </Button>
              }
              {selectedEmails.length > 0 &&
                <Button
                  onClick={() => removeContact(selectedEmails)}
                >
                  Bulk Delete
                </Button>
              }
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Select</TableHeaderCell>
                  <TableHeaderCell>First Name</TableHeaderCell>
                  <TableHeaderCell>Last Name</TableHeaderCell>
                  <TableHeaderCell>Email</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableRow>
              </TableHead>
              {fetchedEmails.length > 0 &&
                <TableBody>
                  {fetchedEmails.map((email, index) => {
                    return (
                      <TableRow key={email.emailAddress}>
                        <TableCell>
                          <input
                            type="checkbox"
                            checked={selectedEmails.includes(email)}
                            onChange={(e) => handleChange(email, e.target.checked)}
                          />
                          <br />
                        </TableCell>
                        <TableCell>{email.firstName}</TableCell>
                        <TableCell>{email.lastName}</TableCell>
                        <TableCell>{email.emailAddress}</TableCell>
                        <TableCell>
                          <Button onClick={() => removeContact(email)}>
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              }
            </Table>
          </Card>
        </div>

        <div className="text-center pb-10">
          <Button onClick={() => navigate('/dashboard/')}>Back to Dashboard</Button>
        </div>

      </div>
    </>
  )
}

export default Contacts;