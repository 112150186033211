import React, { useState } from "react";

// Firebase
import { db } from "../../firebase";
import { collection, query, where, doc, getDoc, getDocs, updateDoc, getFirestore } from "firebase/firestore";

// UI
import { Button } from "@tremor/react";

// Styles
import style from "./EditUrlForm.module.scss";

function EditUrlForm({pageId}) {

    /* Page URL
  ========================================================= */
  const [isEditing, setEditingStatus] = useState(false);
  const [pageSlug, setPageSlug] = useState("");
  const [slugInput, setSlugInput] = useState("");


  function updatePageSlug(e) {
    setSlugInput(e.target.value);
  }

  async function editURL(e) {
    e.preventDefault();
    const regex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
    if (regex.test(slugInput)) {
      try {
        const q = query(collection(db, 'pages'), where('pageSlug', '==', slugInput));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty || querySnapshot.docs[0].id === pageId) {
          setPageSlug(slugInput);
          pushPageSlug(slugInput);
          setEditingStatus(false);
        } else {
          alert('The page slug is already taken.');
        }
      } catch (error) {
        console.error('Error checking custom URL availability: ', error);
      }
    } else {
      alert('Please enter a valid page slug');
    }
  }

  function cancelEdit(e) {
    e.preventDefault();
    setPageSlug(pageId);
    setEditingStatus(false);
  }
  
function pushPageSlug(slug) {
  const db = getFirestore();
    const pageRef = doc(db, "pages", pageId);
    updateDoc(pageRef, {
      pageSlug: slug,
    });
  }

  function fetchPageSlugfromFirebase() {
    const db = getFirestore();
    const pageRef = doc(db, "pages", pageId);
    getDoc(pageRef).then((docSnap) => {
      const docObject = docSnap.data();
      const currentSlug = docObject['pageSlug'];
      setPageSlug(currentSlug);
    });
  }

  fetchPageSlugfromFirebase();

  return (
    <div className="flex items-center justify-center">
      {isEditing === true ?
        <form className="flex items-center justify-center gap-5" onSubmit={editURL}>
          <div class="flex items-center justify-center gap-5">https://wallabie.chee.studio
            <input
              className="m-0 px-5 mh-100 bg-transparent border-b border-solid border-gray-300"
              onChange={updatePageSlug}
              type="text"
              id="slug_value"
              placeholder={pageSlug ? pageSlug : pageId}
              value={slugInput}
            />
          <button className="text-button">Submit</button>
          </div>
          <Button href="#cancel" variant="secondary" onClick={(e)=>cancelEdit(e)}>Cancel Edit</Button>
        </form>
        :
        <div className={style.edit_slug}>
          <div>https://wallabie.chee.studio/<span id="slug-value">{pageSlug ? pageSlug : pageId}</span></div>
          <button className="text-button" onClick={() => setEditingStatus(true)}>Edit</button>
        </div>
      }

    </div>
  );
}

export default EditUrlForm;