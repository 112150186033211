import React, {useEffect, useState} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { Card } from "@tremor/react";
import styles from './UserPages.scss';

const UserPages = ({layout}) => {

  /* User State
  ========================================================= */
  const [user] = useAuthState(auth);

    /* Page Data
  ========================================================= */
  const [pageEntries, setPageEntries] = useState([]);

  useEffect(() => {
    const pagesRef = collection(db, "pages");
    const fetchPages = async () => {
      try {
        const pagesCollection = query(pagesRef, where("owner", "==", user.uid));
        const pagesQuery = await getDocs(pagesCollection);
        const pageEntries = pagesQuery.docs.map((page) => {
          const pageObject = page.data();
          const pageTitle = pageObject.title;
          const pageId = page.id;
          const messageCount = pageObject.messageCount;
          return { title: pageTitle, id: pageId, messageCount: messageCount };
        });
        setPageEntries(pageEntries);
      }
      catch (error) {
        console.log(error);
      }
    };
    fetchPages();
  }, [user.uid]);

  return (
    <Card decoration={`bottom`} decorationColor="sky" className="py-10">
      <h2 className="mb-3">Your Walls</h2>
      {pageEntries.length > 0 ?
        <div className="user-pages-list">
            <div className={`${styles.page_list} grid sm:grid-cols-3 grid-cols-1 gap-2`}>
            {
              pageEntries.map((page) => {
                return (
                  <Card key={page.id} className="p-0 hover:bg-sky-700 transition">
                  <a className="p-3 block text-slate-500 hover:text-white text-sm font-semibold transition" href={`/pages/${page.id}`}>{`${page.title} (${page.messageCount ?? 0})`}</a>
                  </Card>
                )
              })
            }
            </div>
        </div>
        :
        <p>You don't have any walls yet.</p>
      }

    </Card>
  )
}


export default UserPages;