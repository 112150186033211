import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';

// Tremor
import { Badge, Card, Button, SelectBox, SelectBoxItem, Table, TableHead, TableHeaderCell, TableBody, TableRow, TableCell } from "@tremor/react";
import ConfettiExplosion from 'react-confetti-explosion';

// Spinners
import HashLoader from "react-spinners/HashLoader";

// FireBase
import { app } from '../../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../firebase";
import { doc, query, collection, addDoc, getDoc, getDocs, where } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// Tokens
import { v4 as uuidv4 } from 'uuid';

// Components
import UserBar from '../UserBar';

function InviteUsers() {

  /* Auth
  ========================================================= */
  const [user] = useAuthState(auth);

  /* Page Collection
  ========================================================= */
  const pagesRef = useMemo(() => collection(db, "pages"), []);

  /* Page Data
  ========================================================= */
  const [pageSelection, setPageSelection] = useState('');
  const [pageEntries, setPageEntries] = useState([]);

  /* List Pages
  ========================================================= */

  useEffect(() => {
    if (user) {
      (async () => {
        try {
          const pagesCollection = query(pagesRef, where("owner", "==", user.uid));
          const pagesQuery = await getDocs(pagesCollection);
          const pageEntries = pagesQuery.docs.map((page) => {
            const pageObject = page.data();
            const pageTitle = pageObject.title;
            const pageId = page.id;
            return { title: pageTitle, id: pageId };
          });
          setPageEntries(pageEntries);
        }
        catch (error) {
          console.log(error);
        }
      })();
    }
  }, [user, pagesRef]);


  /* Fetch Contacts
========================================================= */

  const [fetchedEmails, setFetchedEmails] = useState([]);

  const fetchContacts = useCallback(async () => {
    if (user) {
      const usersDB = collection(db, "users");
      const userQuery = query(usersDB, where("uid", "==", user.uid));
      getDocs(userQuery).then((querySnap) => {
        const userDocId = querySnap.docs[0].id;
        const docRef = doc(db, "users", userDocId);
        getDoc(docRef).then((docSnap) => {
          const docData = docSnap.data();
          setFetchedEmails(docData.contacts);
        })
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [user]);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  /* Selected Addresses
  ========================================================= */

  const contactsTable = useRef();
  const [selectedContacts, setSelectedContacts] = useState([])

  const handleSelect = (email, checked) => {
    if (checked) {
      setSelectedContacts([...selectedContacts, email]);
    }
    else {
      setSelectedContacts(
        selectedContacts.filter((contact) => contact !== email)
      )
    }
  }

  const [allChecked, setAllChecked] = useState(false);
  const selectAll = () => {

    if (allChecked) {
      setSelectedContacts([]);
    } else {
      const allEmails = fetchedEmails.map(email => email);
      setSelectedContacts(allEmails);
    }
    setAllChecked(!allChecked);
  };

  /* Spinner
  ========================================================= */

  let [loaderColor, setLoaderColor] = useState("");
  useEffect(() => {
    setLoaderColor("#4B7CF6");
  }, []);
  const loaderCSS = {
    display: "block",
    margin: "30px auto",
  };

  /* Tokens
  ========================================================= */
  const [contactInvites, setContactInvites] = useState([]);
  const [tokenStatus, setTokenStatus] = useState("resting");

  const generateTokenLinks = async (e) => {
    e.preventDefault();
    setContactInvites([]);
    setTokenStatus("generating");
    let tokenURLArray = [];
    selectedContacts.forEach(async (contact) => {
      const token = uuidv4();
      let tokenURL;
      try {
        await addDoc(collection(db, 'tokens'), {
          tokenId: token,
          pageId: pageSelection,
          valid: true
        });
        tokenURL = `https://wallabie.chee.studio/pages/${pageSelection}?tokenId=${token}`;
        tokenURLArray.push(tokenURL);
        const combinedArray = tokenURLArray.map((tokenURL, index) => {
          return { email: selectedContacts[index], tokenURL: tokenURL }
        });
        setContactInvites(combinedArray);
        setTimeout(() => {
          setTokenStatus("complete");
        }, 3000)
      }
      catch (error) {
        console.log(error);
      }
    });
  }


  /* Send Email
  ========================================================= */
  const functions = getFunctions(app);

  const [sendingStatus, setSendingStatus] = useState("waiting");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendingStatus("sending");
    const sendSGMail = httpsCallable(functions, 'sendSGMail');
    try {
      const response = await sendSGMail({
        contacts: contactInvites,
      });
      setSendingStatus("complete");
      console.log(response);
    }
    catch (error) {
      console.log(error)
    }
  }

  /* Invite Step
  ========================================================= */
  const [inviteText, setInviteText] = useState("");

  useEffect(() => {
    if (selectedContacts.length === 0) {
      setInviteText("nobody 😿");
      setTokenStatus("resting");
    }
    else if (selectedContacts.length === 1) {
      setInviteText("one lucky person! 🥳");
    }
    else {
      setIsExploding(true);
      setInviteText(selectedContacts.length + " amazing people! 🎉");
    }
    const confettiTimeout = setTimeout(() => {
      setIsExploding(false);
    }, 2000);
    return () => clearTimeout(confettiTimeout);
  }, [selectedContacts.length])


  /* Confetti
  ========================================================= */
  const [isExploding, setIsExploding] = useState(false);
  const confettiProps = {
    force: 0.1,
    duration: 2200,
    particleCount: 80,
    width: 800,
    height: "120vh",
    colors: ['#0ea5e9', '#7c3aed', '#f43f5e'],
  }

  return (
    <div className="invite-users-wrap">
      <UserBar
        user={user}
      />
      <div className="container mx-auto max-w-4xl">
        <h2 className="text-center">Invite Users</h2>

        <Card className="invite-step activated mb-10">
          <h3><Badge size="lg"><strong>1</strong></Badge> Choose Your Wall</h3>
          <SelectBox
            onValueChange={(value) => setPageSelection(value)}
            value={pageSelection}
          >
            <SelectBoxItem
              value={null}
              text="None"
            />
            {pageEntries.map((pageEntry) => {
              return (
                <SelectBoxItem
                  key={pageEntry.id}
                  value={pageEntry.id}
                  text={pageEntry.title}
                />
              )
            })}
          </SelectBox>
        </Card>

        <Card className={`${pageSelection && 'activated'} mb-10 invite-step`}>
          <div className="flex items-start justify-between">
            <h3><Badge size="lg"><strong>2</strong></Badge> Choose Recipients</h3>
            <Button onClick={selectAll}>
              {allChecked ? "Deselect" : "Select"} All
            </Button>
          </div>
          <Table ref={contactsTable}>
            <TableHead>
              <TableRow>
                <TableHeaderCell>Select</TableHeaderCell>
                <TableHeaderCell>First Name</TableHeaderCell>
                <TableHeaderCell>Last Name</TableHeaderCell>
                <TableHeaderCell>Email</TableHeaderCell>
              </TableRow>
            </TableHead>
            {fetchedEmails &&
              <TableBody>
                {fetchedEmails.map((email, index) => {
                  return (
                    <TableRow key={email.emailAddress}>
                      <TableCell>
                        <input
                          type="checkbox"
                          checked={selectedContacts.includes(email)}
                          onChange={(e) => handleSelect(email, e.target.checked)}
                        />
                        <br />
                      </TableCell>
                      <TableCell>{email.firstName}</TableCell>
                      <TableCell>{email.lastName}</TableCell>
                      <TableCell>{email.emailAddress}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            }
          </Table>
        </Card>

        <Card className={`${selectedContacts.length > 0 && pageSelection && 'activated'} mb-10 text-center invite-step`}>
          <div className="flex justify-between items-baseline">
            <h3><Badge size="lg"><strong>3</strong></Badge> Email Invites</h3>
          </div>

          {sendingStatus === "waiting" &&
            <h3 className="text-lg text-center font-bold">Inviting {inviteText}</h3>
          }

          {selectedContacts.length > 0 && tokenStatus !== "complete" &&
            <Button
              className="mb-5"
              onClick={generateTokenLinks}
            >
              Generate Invites
            </Button>
          }


          {tokenStatus === "complete" && sendingStatus === "waiting" &&
            <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
              <div className="mb-8 text-center">
                <Button>Send Invites</Button>
              </div>
            </form>
          }

          {(tokenStatus === "generating" || sendingStatus === "sending") &&
            <HashLoader
              color={loaderColor}
              size={50}
              cssOverride={loaderCSS}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          }

          {sendingStatus === "complete" &&
            <div className="max-w-lg mx-auto">
              <div className="mb-8 text-center">
                <h3 className="text-lg text-center font-bold">And they're off! 📨</h3>
                <p className="text-sm">You can regenerate more invites anytime.</p>
              </div>
            </div>
          }

          {isExploding &&
            <ConfettiExplosion
              {...confettiProps}
            />}
        </Card>


      </div>
    </div>
  );
}

export default InviteUsers;