import React from 'react';

function ValidateUser(Component) {
  return function CheckedComponent(props) {
    if (props.user?.email) {
      return <Component {...props} />;
    }
  }
}

export default ValidateUser;