import React from "react";

// Auth
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";

// Tremor
import { Badge, Card, Button, Divider } from "@tremor/react";


// Components
import UserPages from "../UserPages";
import UserBar from "../UserBar";
import CreateWall from "../CreateWall";

function Dashboard() {

  /* User State
  ========================================================= */
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  /* User Redirect
========================================================= */

  if (!(user?.email)) return navigate("/login");

  return (
    <>
      <div className="dashboard-wrap">

        <UserBar
          user={user}
        />

        <div className="dashboard-wrap-inner py-10 container">

          <div className="dashboard-user-data max-w-2xl mx-auto">

            <Card decoration="bottom" decorationColor="teal" className={`py-10 mb-10 create-wall`}>
              <h2 className="flex items-center mb-3">
                <Badge className="mr-2" size="lg"><strong>1</strong></Badge>
                Start a Wall</h2>
              <CreateWall />
            </Card>

            <Card decoration="bottom" decorationColor="blue" className="py-10 px-7 mb-10 contact-management-card basis-1/3">
              <div className="pb-8">
                <h2 className="flex items-center mb-3"><Badge className="mr-2" size="lg"><strong>2</strong></Badge>Upload Contacts</h2>
                <p className="mb-2">Upload a CSV of your contacts, or input them manually.</p>
                <Button onClick={() => navigate('/contacts/')}>Manage Contacts</Button>
              </div>
            </Card>

            <Card decoration="bottom" decorationColor="yellow" className="py-10 px-7 mb-10 invite-users-card">
              <div className="pb-0">
                <h2 className="flex items-center mb-3"><Badge className="mr-2" size="lg"><strong>3</strong></Badge>Invite Users</h2>
                <p className="mb-2">Send invitations for users to post on one of your walls.</p>
                <Button onClick={() => navigate('/invite/')}>Prepare Invites</Button>
              </div>
            </Card>

          </div>

          <Divider className="my-10 max-w-2xl" />

          <div className="user-pages max-w-3xl mx-auto">
            <UserPages />
          </div>

        </div>
      </div>

    </>
  )
}

export default Dashboard;

