import React from "react";
import { Link } from "react-router-dom";
import { logout } from "../../firebase";
import { Icon } from "@tremor/react";
import { HomeIcon, Cog6ToothIcon, PowerIcon } from "@heroicons/react/24/solid";
import logo from '../../assets/images/logo.svg';

import ValidateUser from "../ValidateUser";

function UserBar({user}) {

  return (
    <>
      <div className="user-bar-wrap pt-7 pb-5 mb-5 bg-white/90 shadow-md shadow-neutral-100/50">
        <div className="container mx-auto">
          <div className="flex items-center justify-end gap-5">
            <Link to="/dashboard" className="mr-auto ml-0" reloadDocument>
            <img className="max-w-[200px]" src={logo} alt="" />
            </Link>
              <Link to="/dashboard" className="user-bar-entry" reloadDocument>
                <Icon
                  icon={HomeIcon}
                  variant="light"
                  tooltip="Dashboard"
                  color="slate"
                />
              </Link>
              <Link to="/settings" className="user-bar-entry">
                <Icon
                  icon={Cog6ToothIcon}
                  variant="light"
                  tooltip="Settings"
                  color="slate"
                  className="transition"
                />
              </Link>
              <button onClick={logout} className="user-bar-entry">
                <Icon
                  icon={PowerIcon}
                  variant="light"
                  tooltip="Logout"
                  color="slate"
                  className="transition"
                />
              </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ValidateUser(UserBar);