import React, { useState } from "react";
import EditUrlForm from "../EditUrlForm";
import ThemeSelector from "../ThemeSelector";
import ValidateUser from "../ValidateUser";

import { Toggle, ToggleItem } from "@tremor/react";

function WallControls({ user, pageId, setTheme }) {

  const [settings, setSettings] = useState('url');

  return (
    <div className="bg-white/50 max-w-xl mx-auto rounded-md p-5">
    
        <Toggle className="mb-5" defaultValue={settings} onValueChange={(value) => setSettings(value)}>
          <ToggleItem value="url" text="Set URL" />
          <ToggleItem value="theme" text="Set Theme" />
        </Toggle>
      
      {settings === 'url' &&
        <EditUrlForm pageId={pageId} />
      }

      {settings === 'theme' &&
        <ThemeSelector pageId={pageId} onSetTheme={setTheme} />
      }

    </div>
  )
}

export default ValidateUser(WallControls);