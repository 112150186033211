import React, { useEffect } from "react";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import LoginForm from "../LoginForm";
import logo from '../../assets/images/logo.svg';

function Login() {

  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user && user.email !== null) navigate("/dashboard");
  }, [user, loading, navigate]);

  return (
    <div className="container mx-auto min-h-[100vh] flex place-content-center flex-wrap">
      <div className="inner border border-[#eee] p-10 max-w-[600px] w-full">
        <div className="max-w-[300px] mx-auto mb-10">
          <img src={logo} alt="" />
        </div>
        <LoginForm/>
      </div>
    </div>
  )
}

export default Login;