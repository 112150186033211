import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { registerWithEmailAndPassword } from "../../firebase";

import logo from '../../assets/images/logo.svg';

import { TextInput, Button } from "@tremor/react";

function Login() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  return (
    <div className="container mx-auto min-h-[100vh] flex place-content-center flex-wrap">
      <div className="border border-[#eee] p-10 max-w-[600px] w-full">
          <div className="">
            <img className="max-w-xs mx-auto mb-5" src={logo} alt="" />
            <h2 style={{ textAlign: "center" }}>Create Your Account</h2>
          </div>
          <form onSubmit={
            (event) => {
              event.preventDefault();
              const auth = getAuth();
              registerWithEmailAndPassword(auth, email, password, name).then((userCredential) => {
                navigate("/dashboard");
              })
            }
          }>
            <fieldset className="field_entry">
              <label htmlFor="email">Name</label>
              <TextInput id="name" type="text" placeholder="Enter name..." onChange={
                (e) => setName(e.target.value)
              } />
            </fieldset>
            <fieldset className="field_entry">
              <label htmlFor="email">Email</label>
              <TextInput id="email" type="email" placeholder="Enter your email..." onChange={
                (e) => setEmail(e.target.value)
              } />
            </fieldset>
            <fieldset className="field_entry">
              <label htmlFor="password">Password</label>
              <TextInput id="password" type="password" placeholder="Choose your password..." onChange={
                (e) => setPassword(e.target.value)
              } />
            </fieldset>
            <div className="">
              <div className="login-button-entry">
                <Button
                  className="login__btn"
                >
                  Create Account
                </Button>
              </div>

            </div>

          </form>
      </div>
    </div>
  )
}

export default Login;