import React, { useState, useEffect, useRef } from "react";

// Router
import { useLocation } from 'react-router-dom';

// Firebase
import { app } from '../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { db } from "../../firebase";
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { logout } from "../../firebase";

// Tremor 
import { Button, Callout, Card, TextInput } from "@tremor/react";

// Effects
import ConfettiExplosion from 'react-confetti-explosion';
import { CSSTransition } from 'react-transition-group';
import HashLoader from "react-spinners/HashLoader";

// Styles
import './styles.scss';

function MessageForm({ onSubmit }) {

  /* Token
  ========================================================= */
  const auth = getAuth();
  
  const [tokenStatus, setTokenStatus] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [tokenUsed, setTokenUsed] = useState(false);
  const [isValidating, setIsValidating] = useState(true);
  
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
  let queryString = useQuery();
  let tokenQuery = queryString.get("tokenId");
  
  useEffect(() => {
    const functions = getFunctions(app);
    const validateToken = httpsCallable(functions, 'validateToken');
    if (tokenQuery) {
      validateToken({ token: tokenQuery })
        .then((result) => {
          // console.log(result.data); // { valid: true } or { valid: false }
          if (result.data.valid) {
            console.log('token validated');
            signInWithCustomToken(auth, result.data.customToken)
              .then(() => {
                setTokenStatus(true);
                setIsValidating(false);
              })
              .catch((error) => {
                // handle error here
                console.error(error);
              })
              ;

          }
          else {
            console.log('token NOT valid');
            setInvalidToken(true);
            setIsValidating(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [tokenQuery, auth]);


  /* Message Functions
  ========================================================= */

  const [message, setMessage] = useState("");
  const [messageName, setMessageName] = useState("");
  const textareaRef = useRef();

  /* Animation Effects
========================================================= */
  const [isExploding, setIsExploding] = useState(false);
  let [loaderColor, setLoaderColor] = useState("");
  useEffect(() => {
    setLoaderColor("#4B7CF6");
  }, []);
  const loaderCSS = {
    display: "block",
    margin: "30px auto",
  };

  /* Confirmation
  ========================================================= */
  const [confirmationMessage, setConfirmationMessage] = useState({ message: "", name: "" });
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.length < 1) {
      alert('Please enter a message');
      return;
    }
    textareaRef.current.focus();
    onSubmit({ message, messageName });
    setShowConfirmationMessage(true);
    setIsExploding(true);
    setConfirmationMessage({
      message: message,
      name: messageName
    });

    const tokenCollection = collection(db, "tokens");
    const tokenDocs = query(tokenCollection, where('tokenId', '==', tokenQuery));
    console.log(tokenDocs);
    const tokenSnap = await getDocs(tokenDocs);

    if (!tokenSnap.empty) {
      const currentToken = tokenSnap.docs[0];
      if (currentToken.data().valid) {
        const tokenRef = doc(db, "tokens", currentToken.id);
        await updateDoc(tokenRef, {
          valid: false
        });
        console.log('Token invalidated in Firestore');
      }
    }

    setMessage("");
    setMessageName("");
    setTokenUsed(true);
    setTokenStatus(false);

    setTimeout(() => {
      setIsExploding(false);
    }, 3000);
  }

  const confettiProps = {
    force: 0.1,
    duration: 2200,
    particleCount: 80,
    width: 800,
    height: "120vh",
    colors: ['#0ea5e9', '#7c3aed', '#f43f5e'],
  }


  return (
    <div className="message-form-wrap">


      {isExploding &&
        <ConfettiExplosion
          {...confettiProps}
        />}

      <CSSTransition
        in={showConfirmationMessage}
        timeout={300}
        classNames="confirmMessage"
        onExited={() => setShowConfirmationMessage(false)}
        mountOnEnter
        unmountOnExit
        appear
      >
        <Card className="text-center mb-10 w-4/12 mx-auto confirmation-message relative" decoration="bottom" decorationColor="rose">
          <small className="text-gray-500 italic mb-5 block text-xs">Note: your message will show <br /> when the administator approves it</small>
          <p className="text-gray-500 text-sm font-normal">{confirmationMessage.message}</p>
          <p className="text-gray-500 text-sm font-normal">{confirmationMessage.name}</p>
        </Card>
      </CSSTransition>


      <div className="wall-message-submit-control">


        {tokenQuery && isValidating && // loading status while token validates
          <HashLoader
            color={loaderColor}
            size={50}
            cssOverride={loaderCSS}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        }

        {tokenStatus === true && // token is valid, show message form
          <form className="container mx-auto max-w-md" onSubmit={handleSubmit}>
            <div className="tremor-TextInput-root relative w-full flex items-center min-w-[10rem] focus:outline-none bg-white hover:bg-gray-50 text-gray-500 border-gray-300 rounded-md border shadow-sm mb-5">
              <textarea
                className="tremor-TextInput-input w-full focus:outline-none focus:ring-2 focus:ring-blue-200 bg-transparent pl-4 pr-4 py-2 text-sm font-medium border-0 placeholder:text-gray-500 h-36"
                placeholder="Write your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                id="messageEntry"
                ref={textareaRef}
              />
            </div>
            <TextInput
              className="mb-5"
              type="text"
              placeholder="Your name..."
              value={messageName}
              onChange={(e) => setMessageName(e.target.value)}
              id="messageName"
            />
            <Button>
              <span>Submit</span>
            </Button>
          </form>
        }


        {tokenUsed && // if token has been used, show this message
          <>
          <Callout
            className="max-w-md mx-auto mb-5 text-left"
            color="cyan"
            title="This token has now been used! 🥳"
          >
            You just made somebody's day.
          </Callout>

          <Button onClick={logout}>Go Back Home</Button>
          </>
        }


        {tokenQuery && invalidToken === true && // token is invalid
          <Callout
            className="max-w-md mx-auto text-left"
            color="orange"
            title="Your token is not valid or has been used already. 😓"
          >
            Send a note to the wall owner to get a new invitation
          </Callout>
        }

      </div>

    </div>
  )
}

export default MessageForm;