import React, { useState, useEffect } from "react";
import { useAuthState, } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { updateEmail } from "firebase/auth";
import { collection, doc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { TextInput, Button } from "@tremor/react";
import UserBar from "../UserBar";

export default function Settings() {

  /* User State
  ========================================================= */
  const [user] = useAuthState(auth);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const setFields = async () => {
      if (!user) return; 
      await doc(collection(db, 'users'));
      try {
        setEmailAddress(user.email);
      }
      catch (error) {
        console.log(error);
      }
    }
    setFields();
  }, [user]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    updateEmail(auth.currentUser, emailAddress).then(() => {
      alert('email updated');
    }).catch((error) => {
      console.log(error);
    });
    updateEmail(auth.currentUser, {
      displayName: `${firstName} ${lastName}`,
    }).then(() => {
      alert('profile updated');
    }).catch((error) => {
      console.log(error);
    });


  };

  /* User Redirect
  ========================================================= */
  const navigate = useNavigate();
  if (!user) return navigate("/");

  return (
    <div className="user-settings-wrap">
      <UserBar
        user={user}
      />
      <div className="container mx-auto py-10">
        <div className="py-10">
          <h2 className="text-center">Your Profile</h2>

          <form className="max-w-lg mx-auto" onSubmit={handleSubmit}>
            <div className="flex gap-5">
              <div className="mb-8 flex-1">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="firstName"
                >
                  First Name
                </label>
                <TextInput
                  type="text"
                  id="firstName"
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="mb-8 flex-1">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="lastName"
                >
                  Last Name
                </label>
                <TextInput
                  type="text"
                  id="lastName"
                  placeholder="Enter Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-8">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="emailAddress"
              >
                Email Address
              </label>
              <TextInput
                type="email"
                id="emailAddress"
                placeholder="Enter Email"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </div>
            <div className="mb-8">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <TextInput
                type="password"
                id="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="mb-8">
              <Button>Submit</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
